@use '@angular/material' as mat;

@import './variables.scss';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$word-app-primary: mat.m2-define-palette($mat-word);
$word-app-accent: mat.m2-define-palette(mat.$m2-blue-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$word-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$word-app-theme: mat.m2-define-light-theme(( color: ( primary: $word-app-primary, accent: $word-app-accent, warn: $word-app-warn, ) ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($word-app-theme);

.pulte-clickable {
	cursor: pointer;
}
